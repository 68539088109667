import { createAction, props } from '@ngrx/store';
import { ChangeLogViewModel, ChangelogFiltering, ChangelogDropdownsViewModel } from '../../models';
import { FailAction } from 'src/app/sport-betting/common/models';

export const getEventChangeLogs = createAction(
  '[Event Change Log] Get Event Change Logs',
  props<{ eventId: number; filters: ChangelogFiltering }>()
);

export const setEventChangeLogs = createAction(
  '[Event Change Log] Set Event Change Logs',
  props<{ changeLogs: ChangeLogViewModel[], totalCount: number }>()
);

export const eventChangeLogsFailure = createAction(
  '[Event Change Log] Event Change Logs Failure',
  props<FailAction>()
);

export const getDropdownData = createAction(
  '[Event Change Log] Get Dropdown Data',
  props<{ eventId: number }>()
);

export const setDropdownData = createAction(
  '[Event Change Log] Set Dropdown Data',
  props<{ dropdownData: ChangelogDropdownsViewModel }>()
);

export const dropdownDataFailure = createAction(
  '[Event Change Log] Dropdown Data Failure',
  props<FailAction>() 
);