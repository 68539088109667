import { createReducer, on } from '@ngrx/store';
import * as ChangeLogActions from '../actions/event-changelog.actions';
import { ChangeLogViewModel, ChangelogDropdownsViewModel } from '../../models';

export interface EventChangeLogState {
  dropdownData: ChangelogDropdownsViewModel | null;
  changeLogs: ChangeLogViewModel[];
  totalCount: number
  error: any;
}

export const initialState: EventChangeLogState = {
  dropdownData: null,
  changeLogs: null,
  totalCount: 0,
  error: null
};

export const eventChangeLogReducer = createReducer(
  initialState,
  on(ChangeLogActions.setEventChangeLogs, (state, { changeLogs, totalCount }) => ({ ...state, changeLogs, totalCount })),
  on(ChangeLogActions.eventChangeLogsFailure, (state, { error }) => ({ ...state, error })),
  on(ChangeLogActions.setDropdownData, (state, { dropdownData }) => ({ ...state, dropdownData })),
  on(ChangeLogActions.dropdownDataFailure, (state, { error }) => ({ ...state, error }))
);
